import * as React from "react";
import CustomerReportTable from "./CustomerReportTable";
import { Box, Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ModalEditReport from "./ModalEditReport";
import { getPageConfig, upsertPageConfig } from "../GQLQueriesPages";
import { useMutation, useQuery } from "@apollo/client";
import EmptyComponent from "./EmptyComponent";
export const CUSTOMER_REPORTS_TABLES_CODE = "customer-reports-tables";
function CustomerReportsTables() {
	const { data, loading } = useQuery(getPageConfig, { variables: { code: CUSTOMER_REPORTS_TABLES_CODE } });
	const [upsert] = useMutation(upsertPageConfig);
	const [reports, setReports] = React.useState([]);
	const [isOpen, setIsOpen] = React.useState(false);
	const handleOpenModal = () => {
		setIsOpen(true);
	};
	const handleCloseModal = () => {
		setIsOpen(false);
	};
		const handleDelete = (index) => {
		let newReports = reports.filter((_, i) => i !== index);
		upsert({ variables: { code: CUSTOMER_REPORTS_TABLES_CODE, config: newReports } });
		setReports(newReports);
	};
	const handleEdit = (index,values) => {
		let newReports = reports.map((report,i) => i === index ? values : report);
		upsert({ variables: { code: CUSTOMER_REPORTS_TABLES_CODE, config: newReports } });
		setReports(newReports);
	};
	const handleSubmit = (report) => {
		let newReports = [report, ...reports];
		upsert({ variables: { code: CUSTOMER_REPORTS_TABLES_CODE, config: newReports } });
		setReports(newReports);
		setIsOpen(false);
	};
	React.useEffect(() => {
		let newReports = [];
		if (data?.pageconfig?.config) {
			newReports = data.pageconfig.config;
		}
		
		setReports(newReports);
	}, [data]);
	
	return (
		<Stack direction="column" spacing={2}>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button variant="contained" color="primary" onClick={handleOpenModal}>
					<AddIcon /> Agregar reporte
				</Button>
			</Box>
			<ModalEditReport isOpen={isOpen} onClose={handleCloseModal} onSubmit={handleSubmit} />
			<Stack direction="column" spacing={2}>
				{reports.map((report,index) => (
					<CustomerReportTable key={report.title} typeUser={report.typeUser} groupsBy={report.groupsBy} columns={report.columns} title={report.title} filters={report.filters} onEdit={(values) => handleEdit(index,values)} onDelete={() => handleDelete(index)} />
				))}
			</Stack>
		</Stack>
	);
}

export default CustomerReportsTables;
