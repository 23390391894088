import { gql, useQuery } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import _ from "lodash";
import * as React from "react";
import { useField } from 'react-final-form';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function FormSelectColumnValues({ column, onChange, name, label,defaultValue, ...props }) {
	const { input, meta } = useField(name);
	
	const QUERY = gql`
		query getColumnValues($column: String!) {
			columns: getColumnValues(column: $column) 
		}
	`;
	const { data: { columns = [] } = {} } = useQuery(QUERY, { fetchPolicy: "cache-first",variables: { column } });

	



	
	const list = React.useMemo(() => {
		const newColumns = [];
		_.forEach(columns, (column) => {
			newColumns.push({ id: column, label: column });
		});
		return newColumns;
	}, [columns]);
	const [selectedItems, setSelectedItems] = React.useState(defaultValue || []);

	const handleChange = (event) => {
		if (input?.onChange) {
			input.onChange(event);
		  }
		const ids = event.target.value;
		setSelectedItems(ids);
		if (onChange) {
			onChange(ids);
		}
	};

	return (
		<div>
			<FormControl sx={{  width: 290 }}>
				<InputLabel >{label}</InputLabel>
				<Select
					multiple
					input={<OutlinedInput label="Tag" />}
					renderValue={(selected) => selected.join(", ")}
					MenuProps={MenuProps}
					{...props}
					{...input}
					onChange={handleChange}
					value={selectedItems}
					error={meta.error && meta.touched}
					helperText={meta?.error}
					>
					{list.map((column) => (
						<MenuItem key={column.id} value={column.id}>
							<Checkbox checked={selectedItems.includes(column.id)} />
							<ListItemText primary={column.label} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
