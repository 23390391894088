/* eslint-disable react-hooks/exhaustive-deps */
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, RadialLinearScale, Title, Tooltip } from 'chart.js';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Bar, Doughnut, Line, Pie, Radar } from 'react-chartjs-2';
import EmptyComponent from './EmptyComponent';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, RadialLinearScale);

const defaultOptions = {
  responsive: true,
  onResize: true,
  maintainAspectRatio: false,
  plugins: {
    legend: null,
    title: {
      display: false,
      text: 'Chart.js Bar Chart'
    }
  }
};

export const VARIANTS = {
  BAR: 'bar',
  PIE: 'pie',
  DOUGHNUT: 'doughnut',
  LINE: 'line',
  RADAR: 'radar',
  COLUMN: 'column'
};
export const Components = {
  [VARIANTS.BAR]: Bar,
  [VARIANTS.PIE]: Pie,
  [VARIANTS.DOUGHNUT]: Doughnut,
  [VARIANTS.LINE]: Line,
  [VARIANTS.RADAR]: Radar,
  [VARIANTS.COLUMN]: Bar
};
/**
 * Opciones por defecto para cada tipo de grafico
 */
const getOptionsByVariant = (variant) => {
  let options = {};
  switch (variant) {
    case VARIANTS.COLUMN:
      options = {
        indexAxis: 'y'
      };
      break;
    default:
      break;
  }
  return options;
};
/**
 * Componete para el manejo de graficos
 * @returns
 */
function ChartBox({ data, options: _options = {},  typeChart = 'bar' }) {
  const Component = useMemo(() => Components[typeChart], [typeChart]);
  const options = useMemo(() => ({ ...defaultOptions, ...getOptionsByVariant(typeChart), ..._options }), [_options]);
  return (
    <>
      {!data && <EmptyComponent />}
      {data && <Component options={options} data={data} />}
    </>
  );
}

ChartBox.propTypes = {
  data: PropTypes.object,
  options: PropTypes.any,
  typeChart: PropTypes.string
};
export default ChartBox;
